import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'normalize.css/normalize.css';
import './utils/rem.js';
// 引入amfe-flexible
import 'amfe-flexible/index';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
// import { RecycleScroller } from 'vue-virtual-scroller';
import store from '@/store';
import mitt from "mitt";
import './assets/fonts/iconfont.css'
const app = createApp(App);
app.config.globalProperties.$bus = new mitt()
// app.component('RecycleScroller', RecycleScroller);
app.use(VueVirtualScroller);
app.use(router);
app.use(store);
app.mount('#app');
