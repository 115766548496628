import { yearList, getZgqs, getzwpw, supports, gethyyc, getztyj, getpxlt, gethjlw, getqkzc, gettgly, getzysx, getltzs, getGylt, getlsh, topBann, getjccx, yearListNav } from '@/api/meeting.js';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import axios from 'axios'
export default {
    state: () => ({
        year: {
            id: 11,
            years: 2024
        },
        yearsList: [],
        // 征文启示
        zgqs: {},
        // 组委与评委
        zwpw: {},
        // 会议日程
        hyyc: {},
        // 主题演讲
        ztyj: {},
        // 平行论坛
        pxlt: {},
        // 获奖论文
        hjlw: {},
        // 期刊支持
        qkzc: {},
        // 坛光掠影
        tgly: {},
        // 下载专区
        zysx: {},
        // 论坛综述
        ltzs: {},
        // 关于论坛
        gylt: {},
        // 理事会
        lsh: {},
        // 轮播图
        banner: {
            info_name: '',
            info_imgs: '',
            info_solog: ''
        },
        // 精彩纷呈
        jccx: {},
        supportObj: {}
    }),
    mutations: {
        // 年份
        setYear(state, year) {
            NProgress.start();
            state.year = year;
            const timer = setTimeout(() => {
                NProgress.done()
                clearTimeout(timer)
            }, 1500)
        },
        // 年份列表
        setYearsList(state, yearsList) {
            state.yearsList = yearsList;
        },
        // 征文启示
        setZgqs(state, zgqs) {
            state.zgqs = zgqs;
        },
        // 组委与评委
        setZwpw(state, zwpw) {
            state.zwpw = zwpw;
        },
        // 会议日程
        setHyyc(state, hyyc) {
            state.hyyc = hyyc;
        },
        // 主题演讲
        setZtyj(state, ztyj) {
            state.ztyj = ztyj;
        },
        // 平行论坛
        setPxlt(state, pxlt) {
            state.pxlt = pxlt;
        },
        // 获奖论文
        setHjlw(state, hjlw) {
            state.hjlw = hjlw;
        },
        // 期刊支持
        setQkzc(state, qkzc) {
            state.qkzc = qkzc;
        },
        // 坛光掠影
        setTgly(state, tgly) {
            state.tgly = tgly;
        },
        // 下载专区
        setZysx(state, zysx) {
            state.zysx = zysx;
        },
        // 论坛综述
        setLtzs(state, ltzs) {
            state.ltzs = ltzs;
        },
        // 关于论坛
        setGylt(state, gylt) {
            state.gylt = gylt;
        },
        // 理事会
        setLsh(state, lsh) {
            state.lsh = lsh;
        },
        setSupport(state, data) {
            state.supportObj = data
        },
        // 轮播图
        setTopBanner(state, {data}) {
            state.banner = data;
        },
        // 精彩纷呈
        setJccx(state, jccx) {
            state.jccx = jccx;
        },
        // 重置状态
        resetState(state) {
            state.year = {
                id: '',
                years: ''
            };
            state.zgqs = {};
            state.zwpw = {};
            state.hyyc = {};
            state.ztyj = {};
            state.pxlt = {};
            state.hjlw = {};
            state.qkzc = {};
            state.tgly = {};
            state.zysx = {};
            state.ltzs = {};
            state.gylt = {};
            state.lsh = {};
            state.banner = [];
            state.jccx = {};
        }
    },
    actions: {
        // 年份列表
        getYear({ commit }) {
            return new Promise((resolve, reject) => {
                yearList()
                    .then((response) => {
                        const { data } = response;
                        // commit('setYearsList', data);
                        commit('setYear', data[0]);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async getNavYear({ commit }) {
            const res = await yearListNav()
            // commit('setYearsList', res.data);
        },
        // 征文启示
        getZgqs({ commit, state }) {
            return new Promise((resolve, reject) => {
                getZgqs({ cateId: '10', yearId: state.year.id })
                    .then(async (response) => {
                        const { data } = response;
                        await commit('setZgqs', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 组委与评委
        getZwpw({ commit, state }) {
            return new Promise((resolve, reject) => {
                getzwpw({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setZwpw', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 会议日程
        getHyyc({ commit, state }) {
            return new Promise((resolve, reject) => {
                gethyyc({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setHyyc', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 主题演讲
        getZtyj({ commit, state }) {
            return new Promise((resolve, reject) => {
                getztyj({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setZtyj', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 平行论坛
        getPxlt({ commit, state }) {
            return new Promise((resolve, reject) => {
                getpxlt({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setPxlt', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 获奖论文
        getHjlw({ commit, state }) {
            return new Promise((resolve, reject) => {
                gethjlw({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setHjlw', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 期刊支持
        getQkzc({ commit, state }) {
            return new Promise((resolve, reject) => {
                getqkzc({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setQkzc', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 坛光掠影
        getTgly({ commit, state }) {
            return new Promise((resolve, reject) => {
                gettgly({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setTgly', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 下载专区
        getZysx({ commit, state }) {
            return new Promise((resolve, reject) => {
                getzysx({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setZysx', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 论坛综述
        getLtzs({ commit, state }) {
            return new Promise((resolve, reject) => {
                getltzs({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setLtzs', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 关于论坛
        getGylt({ commit, state }) {
            return new Promise((resolve, reject) => {
                getGylt({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setGylt', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 理事会
        getLsh({ commit, state }) {
            return new Promise((resolve, reject) => {
                getlsh({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setLsh', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 理事会
        async getSupport({ commit, state }) {
            return new Promise((resolve, reject) => {
                supports({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        console.log(response);
                        const { data } = response;
                        commit('setSupport', data)
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 精彩纷呈
        getJccx({ commit, state }) {
            return new Promise((resolve, reject) => {
                getjccx({ cateId: '10', yearId: state.year.id })
                    .then((response) => {
                        const { data } = response;
                        commit('setJccx', data);
                        setTimeout(() => {
                            NProgress.done();
                        }, 500);
                        resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            });
        },

    },
    getters: {
        year: (state) => state.year,
        yearsList: (state) => state.yearsList,
        zgqs: (state) => state.zgqs,
        zwpw: (state) => state.zwpw,
        hyyc: (state) => state.hyyc,
        ztyj: (state) => state.ztyj,
        pxlt: (state) => state.pxlt,
        hjlw: (state) => state.hjlw,
        qkzc: (state) => state.qkzc,
        tgly: (state) => state.tgly,
        zysx: (state) => state.zysx,
        ltzs: (state) => state.ltzs,
        gylt: (state) => state.gylt,
        lsh: (state) => state.lsh,
        banner: (state) => state.banner,
        jccx: (state) => state.jccx
    }
};
