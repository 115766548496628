import request from '@/utils/request';
import axios from "axios";

export function lists() {
    return request({
        url: `/meeting/lists`,
        method: 'POST'
    });
}

// 年份列表
export function yearList() {
    return request({
        url: `/meeting/yearList`,
        method: 'POST',
        data: {
            emulateJSON: true
        }
    });
}
export function yearListNav() {
    return request({
        url: `meeting/yearListNav`,
        method: 'POST',
        data: {
            emulateJSON: true
        }
    });
}

// 轮播图
export function topBann(data) {
    return request({
        url: `/meeting/topBann?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'POST'
    });
}
// 获取征文启示
export function getZgqs(data) {
    return request({
        url: `/meeting/getZgqs?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 获取组委会
export function getzwpw(data) {
    return request({
        url: `/meeting/getzwpw?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 会议议程
export function gethyyc(data) {
    return request({
        url: `/meeting/gethyyc?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 主题演讲
export function getztyj(data) {
    return request({
        url: `/meeting/getztyj?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 平行论坛
export function getpxlt(data) {
    return request({
        url: `/meeting/getpxlt?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 获奖论文
export function gethjlw(data) {
    return request({
        url: `/meeting/gethjlw?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 期刊支持
export function getqkzc(data) {
    return request({
        url: `/meeting/getqkzc?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 精彩呈现
export function getjccx(data) {
    return request({
        url: `/meeting/getjccx?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 坛光掠影
export function gettgly(data) {
    return request({
        url: `/meeting/gettgly2?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
export function getzysx(data) {
    return request({
        url: `/meeting/getzysx?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}

export function getltzs(data) {
    return request({
        url: `/meeting/getltzs?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
export function getGylt(data) {
    return request({
        url: `/meeting/getGylt?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 理事会
export function getlsh(data) {
    return request({
        url: `/meeting/getlsh?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
export function supports(data) {
    return request({
        url: `/meeting/getzzdw?cateId=${data.cateId}&yearId=${data.yearId}`,
        method: 'GET'
    });
}
// 底部图片
export function foot() {
    return request({
        url: `/meeting/foot`,
        method: 'GET'
    });
}

// 获取论坛全部信息
export const forumInfo = () => axios.get( '/api/common/getForumInfo?forumId=1')

export const preview = (originUrl) => axios.get(`/api/common/getPreviewUrl?originalUrl=${'http://tounali.oss-cn-beijing.aliyuncs.com' + originUrl}`)

export const footerImage = () => axios.get("/api/common/getForumLogo")
